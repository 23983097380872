export const generateBill = (productQuantities, productPrices, productNames, eventType) => {
    // Use an array representing all product indices
    const allProductIndices = Array.from({ length: productNames.length }, (_, i) => i + 1);
    const billItems = allProductIndices.map((index) => {
        const key = `product-${index}`;
        const quantity = productQuantities[key] || 0;
        if (quantity > 0) { // Only include items with a quantity greater than zero
            const individualTotal = quantity * productPrices[index - 1];
            return {
                productName: productNames[index - 1],
                quantity,
                individualTotal
            };
        }
        return null;
    }).filter(item => item != null); // Filter out null items (where quantity was zero)

    // Calculate total
    let total = billItems.reduce((acc, item) => acc + item.individualTotal, 0);

    // Calculate tax, service fee, and additional charges
    const tax = total * 0.1025; // 10.25% tax
    let serviceFee;
    if (eventType === 'event') {
        serviceFee = total * 0.15; // 15% service fee for events
    } else {
        serviceFee = total * 0.10; // 10% service fee for others
    }
    const additionalCharges = total > 150 ? 150 : 0; // Additional $150 if total exceeds $150

    // Add tax, service fee, and additional charges to total
    total += tax + serviceFee + additionalCharges;
    
    return {
        items: billItems,
        total,
        tax,
        serviceFee,
        additionalCharges
    };
};
