import React, { useState, useRef } from "react";
import { TextField, Button, Typography, Container } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import logo from './logo.png'; // Assuming the logo is in the same folder
import { styled } from "@mui/system";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { parse } from "date-fns";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { format, parseISO } from "date-fns"; 
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#5021a7', // Keeps the button's default color
          color: 'white', // Sets the default text color to white
          fontSize: '1rem', // Sets the font size
          fontWeight: 'bold', // Makes the font bold
          '&:hover': {
            backgroundColor: '#38127a',
          },
          '&.Mui-contained': { // Style for contained (selected) buttons
            color: 'black', // Dark text color for selected state
          },
        },
      },
    },
  },
});
const StyledForm = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column; // Change to column to stack items vertically
  justify-content: center;
  align-items: center; // Center align items
  height: auto;
  padding: 10px;
  margin-bottom: 70px;
`;
const StickyButton = styled(Button)`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
`;
const OptionButton = styled(Button)`
  width: 100%;
  margin-bottom: 10px;
  &.MuiButton-contained { // Styles for when the button is selected
    color: #00BFFF; // Light blue font color when selected
  }
`;
function ContactForm({ onSubmit }) {
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => {
    const displayDate = value
      ? format(parse(value, "MM-dd-yyyy", new Date()), "MM-dd-yyyy")
      : "";
    return (
      <TextField
        variant="outlined"
        fullWidth
        onClick={onClick}
        value={displayDate}
        ref={ref}
        label="Event Date (MM-dd-yyyy)"
        required
        InputProps={{
          readOnly: true,
        }}
      />
    );
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    eventType: "",
    eventDate: "",
    flowerTheme: "others",
    otherFlowerTheme: "",
    otherEventType: "",
  });
  const [errors, setErrors] = useState({});
  const otherFlowerThemeRef = useRef(null);
  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "Name is required";
    tempErrors.email = formData.email ? "" : "Email is required and must be valid";
    tempErrors.eventType = formData.eventType ? "" : "Please select or input the Event Type";
    tempErrors.eventDate = formData.eventDate ? "" : "Fill the event date";
    tempErrors.flowerTheme = formData.flowerTheme ? "" : "Choose a flower theme";
    if (formData.eventType === "others") {
      tempErrors.otherEventType = formData.otherEventType ? "" : "Specify the event type";
    }
    if (formData.flowerTheme === "others") {
      tempErrors.otherFlowerTheme = formData.otherFlowerTheme ? "" : "Specify the flower theme";
    }  
    return tempErrors;
  };
  const handleEventSelect = (eventType) => {
    setFormData({ ...formData, eventType });
    setErrors({ ...errors, eventType: "" });
  };
  const handleFlowerThemeSelect = (flowerTheme) => {
    setFormData({ ...formData, flowerTheme });
    setErrors({ ...errors, flowerTheme: "" });
    if (flowerTheme === "others") {
      setTimeout(() => {
        otherFlowerThemeRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        otherFlowerThemeRef.current?.focus();
      }, 100); // Add a slight delay to ensure the field is rendered
    }
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };
  const handleDateChange = (date) => {
    if (date && !isNaN(date)) {
      const formattedDate = format(date, "MM-dd-yyyy"); // Change format to "MM-dd-yyyy"
      setFormData({ ...formData, eventDate: formattedDate });
    } else {
      setFormData({ ...formData, eventDate: "" });
    }
    setErrors({ ...errors, eventDate: "" });
  };
  const scrollToFirstError = () => {
    const firstErrorKey = Object.keys(errors).find(key => errors[key]);
    const firstErrorElement = document.querySelector(`[name="${firstErrorKey}"]`);
    if (firstErrorElement) {
      firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      firstErrorElement.focus();
    }
  };
  const fieldNameMapping = {
    name: "",
    email: "",
    phoneNumber: "",
    eventType: "",
    eventDate: "",
    flowerTheme: "",
    otherFlowerTheme: "",
    otherEventType: "",
  };
  const generateErrorMessage = (tempErrors) => {
    const errorFields = Object.entries(tempErrors).filter(([_, value]) => value);
    if (errorFields.length === 0) {
      return "";
    }
    let message = "<strong>Please fill/select the mandatory fields:</strong><br/>";
    errorFields.forEach(([key, value], index) => {
      const friendlyFieldName = fieldNameMapping[key] || key;
      message += `&bull; ${friendlyFieldName}: ${value}<br/>`;
    });
    return message;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validate(); // Obtain new errors from the validate function
    if (Object.keys(newErrors).every((key) => !newErrors[key])) {
      // If there are no errors
      console.log("Form Data on Submit:", formData);
      onSubmit(formData);
    } else {
      // If there are errors
      const errorMessage = generateErrorMessage(newErrors); // Generate error message using new errors
      if (errorMessage) {
        toast.error(<div dangerouslySetInnerHTML={{ __html: errorMessage }} />, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        scrollToFirstError();
      }
    }
  };
  const renderEventOptions = () => (
    <>
      <OptionButton
        variant={formData.eventType === "wedding" ? "contained" : "outlined"}
        color="primary"
        onClick={() => handleEventSelect("wedding")}
      >
        Wedding
      </OptionButton>
      <OptionButton
        variant={formData.eventType === "corporate" ? "contained" : "outlined"}
        color="primary"
        onClick={() => handleEventSelect("corporate")}
      >
        Corporate
      </OptionButton>
      <OptionButton
        variant={formData.eventType === "others" ? "contained" : "outlined"}
        color="primary"
        onClick={() => handleEventSelect("others")}
      >
        Others
      </OptionButton>
      {formData.eventType === "others" && (
        <TextField
          label="Specify the Other Event Type"
          name="otherEventType"
          variant="outlined"
          fullWidth
          required
          onChange={handleChange}
          error={!!errors.otherEventType}
          helperText={errors.otherEventType}
        />
      )}
    </>
  );
  const renderFlowerThemeOptions = () => (
    <>
      <OptionButton
      style={{ display: 'none' }} // Hides the button
        variant={formData.flowerTheme === "others" ? "contained" : "outlined"}
        color="primary"
        onClick={() => handleFlowerThemeSelect("others")}
      >
        Customize your theme
      </OptionButton>
      {formData.flowerTheme === "others" && (
        <>
          <Typography variant="body1" style={{ marginTop: 2 }}>
            Please provide details about your desired flower theme ex) bright yellow and red :
          </Typography>
          <TextField
            ref={otherFlowerThemeRef}
            label="Flower Theme"
            name="otherFlowerTheme"
            variant="outlined"
            fullWidth
            required
            onChange={handleChange}
            error={!!errors.otherFlowerTheme}
            helperText={errors.otherFlowerTheme}
          />
        </>
      )}
    </>
  );
  
  return (
    <ThemeProvider theme={theme}> 
    <StyledContainer>
    <img src={logo} alt="Logo" style={{ width: '100%', maxWidth: '200px', marginBottom: '20px' }} />
    <ToastContainer />
      <StyledForm onSubmit={handleSubmit}>
        <Typography variant="h4" align="center">
          Blossom & Budget Mood Board Visualizer
        </Typography>
        <TextField
          label="Name"
          name="name"
          variant="outlined"
          fullWidth
          required
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          label="Email Address"
          name="email"
          variant="outlined"
          fullWidth
          required
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          label="Phone Number"
          name="phoneNumber"
          variant="outlined"
          fullWidth
          onChange={handleChange}
        />
        <Typography variant="h6">Choose Your Event:</Typography>
        {renderEventOptions()}
        <DatePicker
          selected={
            formData.eventDate
              ? parse(formData.eventDate, "MM-dd-yyyy", new Date())
              : null
          }
          onChange={handleDateChange}
          dateFormat="MM-dd-yyyy"
          customInput={<CustomInput />}
          required
        />      
        <Typography variant="h6">Customize Your Theme:</Typography>
        {renderFlowerThemeOptions()}
        {errors.flowerTheme && (
          <Typography color="error">{errors.flowerTheme}</Typography>
        )}
      </StyledForm>
      <StickyButton
        type="submit"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Submit
      </StickyButton>
    </StyledContainer>
    </ThemeProvider>

  );
}
export default ContactForm;
